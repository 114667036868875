.complete-services-agreement-view {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 800px;

  .complete-services-agreement-form-container {
    .form-masthead {
      p {
        padding: 0 60px;
      }
    }

    .tippy-content {
      @include rem(width, 250px);
    }

    .select-group-label {
      border-top: $border-style;
      padding-top: 10px;
    }

    .form-column {
      fieldset {
        @include rem(margin-bottom, 48px);

        &:last-child {
          @include rem(margin-bottom, 40px);
        }
      }

      .investment-name {
        dd {
          margin: 0;
          padding: 0;
        }
        .button-text {
          @include link-body--sm;
          text-decoration: underline;
          margin-left: -15px;
        }
      }
    }

    .form-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      justify-content: space-between;
      column-gap: 32px;

      .full-row {
        grid-column: 1 / -1;
        padding-bottom: 6px;
      }

      .checkbox {
        top: 40%;
      }
    }

    .signature-details-container {
      dl {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 34px;
        margin-bottom: 26px;
      }
    }
    .e-signature {
      .checkbox {
        label {
          @include text-label--small;
        }
      }
    }
  }
}
