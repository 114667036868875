.faq {
  width: 100%;
  .faq-header-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 800px;
    .faq-header {
      font-family: Inter;
      color: $grey-6;
      font-size: 30px;
      font-weight: 600;
    }
    #faq-contact-button {
      border: 2px solid $indigo-5;
    }

    .faq-contact-us:focus,
    .faq-contact-us:hover {
      color: var(--indigo-3);
    }
  }

  .faq-container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    justify-content: center;
    align-items: center;
    width: 100%;

    p {
      @include text-body--large;
    }
    .faq-search {
      width: fit-content;
      .faq-search-input {
        width: 800px;
      }
    }
    .faq-list {
      gap: 32px;
      display: flex;
      flex-direction: column;
    }
    .faq-card-container {
      border: $border-style;
      background-color: white;
      border-radius: 12px;
      box-shadow: 1px 2px 4px rgba(61, 61, 58, 0.2);
      padding: 36px;
      width: 800px;
      min-height: fit-content;
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;

      .faq-card-header {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 16px;
        color: $grey-6;
        font-family: Inter;
      }

      .faq-accordion-item {
        height: fit-content;
        border-bottom: 1px solid $grey-2;
      }
      .faq-accordion-item:last-child {
        border-bottom: none;
      }

      .faq-question {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border: none;
        background-color: transparent;
        color: $grey-6;
        padding: 5px;
        font-family: Inter;
        font-size: 18px;
      }
      .faq-question-button {
        cursor: pointer;
        font-size: 20px;
        display: flex;
        background-color: transparent;
        font-weight: 600;
        color: $grey-6;
        width: 16px;
        height: 16px;
        padding: 0;
      }

      .faq-answer {
        margin-top: 16px;
        margin-bottom: 16px;
        font-size: 14px;
        padding: 5px;
        font-family: Inter;
        color: $grey-5;

        .faq-answer-header {
          font-size: 14px;
          font-family: Inter;
          font-weight: 600;
          padding-bottom: 16px;
          color: $grey-6;
        }

        li {
          font-size: 14px;
          font-family: Inter;
          font-weight: 600;
          height: fit-content;
          margin: 0;
        }
        li::before {
          content: '\002F';
        }

        .faq-answer-text {
          display: flex;
          flex-direction: column;
          height: fit-content;
          padding-left: 12px;
          padding-right: 12px;
          gap: 6px;
          margin-bottom: 16px;
        }
        .answer-bold-1 {
          font-family: Inter;
          color: $grey-6;
          font-weight: 600;
          margin-bottom: 8px;
        }
        .answer-bold-2 {
          font-family: Inter;
          color: $grey-6;
          font-weight: 600;
          display: inline-block;
        }
        .faq-link {
          font-size: 14px;
          color: $blue-6;
        }
        .faq-gif {
          margin-top: 16px;
          margin-bottom: 16px;
          cursor: pointer;
        }
        .faq-gif-paused {
          display: flex;
          height: 340px;
          width: 100%;
          background-color: $grey-1;
          margin-top: 16px;
          margin-bottom: 16px;
          padding: 120px;
          cursor: pointer;
        }
      }

      .faq-card-content {
        margin-bottom: 24px;
        flex-basis: 100%;
      }
    }
  }
}
